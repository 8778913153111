import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { history } from "_helpers";
import axios from "axios";
import { patientActions, authActions } from "_store";

export { Patient };

let uploadImageType = 'JPG';

function Patient(prop) {
  const { user: authUser } = useSelector((x) => x.auth);
  const { users } = useSelector((x) => x.users);

  const dispatch = useDispatch();
  const logout = () => dispatch(authActions.logout());

  const [patientData, setPatientData] = useState({
    center_key: 0,
    patient_key: 0,
    ref_patient_key: 0,
    patient_id: "",
    patient_name: "",
    patient_sex: "M",
    patient_age: 0,
    patient_age_unit: "Y",
    address: "",
    state: "",
    zipcode: "",
    country: "",
    phone_no: "",
    email: "",
    regn_on: "",
    refer_physician: "",
    modality: "",
    body_part: "",
    study_description: "",
    study_file: "",
    remarks: "",
    internal_comments: "",
    created_by: "",
    created_on: "",
    updated_by: "",
    updated_on: "",
    priority: "N",
    file_upload_key: 0,
    error_code: "",
    error_msg: "",
  });

  const [fileList, setFile] = useState();
  const [fileCount, setFileCount] = useState(0);
  const [fileAttach, setFileAttach] = useState();
  const files = fileList ? [...fileList] : [];
  
  useEffect(() => {
    if (authUser?.upload_preview_flag === "Y") {
      document.getElementById("btnUploadAttachedFiles").innerText =
        " View Selected Files ";
    } else {
      document.getElementById("btnUploadAttachedFiles").innerText =
        " Upload Selected Files ";
    }
  }, []);

  // only show when logged in
  if (!authUser) return null;

  const handleChange = (event) => {
    setPatientData({ ...patientData, [event.target.name]: event.target.value });
  };

  function handleChangeUploadFile(e) {
    if (e.target.files) {
      setFileCount(e.target.files.length);
      setFile(e.target.files);

      uploadImageType = 'JPG';

      if (window.File && window.FileReader && window.FileList && window.Blob) {
        const files = e.target.files;
        //console.log(files[1].name);

        const output = document.querySelector("#result");
        output.innerHTML = "";
        for (let i = 0; i < files.length; i++) {
          if(files[i].name.indexOf('.dcm') > 0)
          {
            uploadImageType = 'DICOM';
          }
          else if(files[i].name.indexOf('.DCM') > 0)
          {
            uploadImageType = 'DICOM';
          }
          else if(files[i].name.indexOf('.dic') > 0)
          {
            uploadImageType = 'DICOM';
          }
          else if(files[i].name.indexOf('.DIC') > 0)
          {
            uploadImageType = 'DICOM';
          }

          if (!files[i].type.match("image")) continue;

          const picReader = new FileReader();
          
          // console.log(picReader);
          picReader.addEventListener("load", function (event) {
            const picFile = event.target;
            //console.log(picFile);
          
            document.getElementById('mainImage').src = picFile.result;

            const div = document.createElement("div");
            div.className = "col-2";
            div.innerHTML = `<div class="mb-2"><div id="display_report1"><img class="thumbnail" src="${picFile.result}" title="${picFile.name}"/>
                                      <p class="pt-1 pb-1 mb-0"><small>Report Name</small></p></div>
                                                                </div>`;
            output.appendChild(div);
          });
          picReader.readAsDataURL(files[i]);
        }
      } else {
        alert("Your browser does not support File API");
      }
    }
  }

  function handleChangeAttach(event) {
    if (event.target.files) {
      setFileAttach(event.target.files[0]);
      //debugger;
      patientData.study_file = event.target.files[0].name;
    }
  }

  function pullPouplate(event) {
    if (event !== null) {
      event.preventDefault();
    }

    if (fileCount === 0) {
      alert("Select files before upload.");
      return false;
    }

    SaveData("UPLOAD");
  }

  function SaveData(action_tag) {
    const action_tag_org = action_tag; 
    const url = `${process.env.REACT_APP_API_URL_EC2}/patients/Patient`;
    const user_key = authUser?.user_key;
    const center_key = authUser?.center_key;
    const upload_preview_flag = authUser?.upload_preview_flag;

    if(center_key===0){
      alert('Cannot allow to upload for login user.'); return false;
    }

    let patient_key = 0;

    if (action_tag === "SAVE_PATIENT") {
      patient_key = patientData.patient_key;
    }

    var LbAddMode =  false;
    if(patient_key===0)
    {
      LbAddMode = true;
    }

    if(action_tag === "UPLOAD" && uploadImageType === 'JPG'){
      if(!ValidateInput(LbAddMode)){
        return false;
      }
    }

    if(patient_key===0 && action_tag !== "UPLOAD"){
      if (fileCount === 0) {
        alert("Select files before upload.");
        return false;
      }

      action_tag = "UPLOAD";
    }
    
    if (document.getElementById("chkurgent").checked) {
      patientData.priority = "Y";
    } else {
      patientData.priority = "N";
    }

    if (action_tag === "SAVE_PATIENT" || uploadImageType === 'JPG') {
      var objddlpatient_sex = document.getElementById("ddlpatient_sex");
      patientData.patient_sex = objddlpatient_sex.value;

      var objddlpatient_age_unit = document.getElementById("ddlpatient_age_unit");
      patientData.patient_age_unit = objddlpatient_age_unit.value;
    }

    // 👇 Create new FormData object and append files
    const data = new FormData();
    data.append(`user_key`, user_key.toString());
    data.append(`center_key`, center_key.toString());
    data.append(`patient_key`, patient_key.toString());
    data.append(`action_tag`, action_tag);

    data.append(`patient_name`, patientData.patient_name);
    data.append(`patient_age`, patientData.patient_age.toString());
    data.append(`patient_age_unit`, patientData.patient_age_unit);
    data.append(`patient_sex`, patientData.patient_sex);
    data.append(`study_description`, patientData.study_description);
    data.append(`refer_physician`, patientData.refer_physician);
    data.append(`remarks`, patientData.remarks);
    data.append(`priority`, patientData.priority);

    data.append(`address`, patientData.address);
    data.append(`state`, patientData.state);
    data.append(`zipcode`, patientData.zipcode);
    data.append(`country`, patientData.country);
    data.append(`phone_no`, patientData.phone_no);
    data.append(`email`, patientData.email);
    data.append(`modality`, patientData.modality);
    data.append(`body_part`, patientData.body_part);
    data.append(`internal_comments`, patientData.internal_comments);

    data.append(`uploadfile_count`, fileCount.toString());

    if (action_tag === "SAVE_PATIENT") {
      ;
    } else {
      files.forEach((file, i) => {
        data.append(`UploadFiles`, file, file.name);
      });
    }

    if (fileAttach) {
      data.append(`study_file`, fileAttach, fileAttach.name);
    }

    ProcessStatus(true);
    
    //const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjIiLCJuYmYiOjE2ODIyNTg2MzgsImV4cCI6MTY4MjMyNzAzOCwiaWF0IjoxNjgyMjU4NjM4fQ.fbCZfsLHAnXu1EQ2u03xFDsIyfSW2y5Soit_i8k9NbU";
    const token = JSON.parse(localStorage.getItem("user")).token;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      axios
        .post(url, data, config)
        .then((response) => {
            ProcessStatus(false);
          //console.log(response.data);
          //alert(JSON.stringify(response.data));

          // console.log(JSON.stringify(response.data));

          let updatedValue = {};
          updatedValue = response.data; //JSON.stringify(response.data);

          setPatientData((patientData) => ({
            ...patientData,
            ...updatedValue,
          }));

          var msg_display = updatedValue.error_msg;

          if (msg_display !== "") {
            if (action_tag !== "SAVE_PATIENT") {
              if (updatedValue.patient_sex !== "") {
                var objddlpatient_sex =
                  document.getElementById("ddlpatient_sex");
                objddlpatient_sex.value = updatedValue.patient_sex;
              }

              if (updatedValue.patient_age_unit !== "") {
                var objddlpatient_age_unit =
                  document.getElementById("ddlpatient_age_unit");
                objddlpatient_age_unit.value = updatedValue.patient_age_unit;
              }

            }

            setTimeout(function () {
              alert(msg_display);
            }, 100);
          } else {
            if (action_tag === "SAVE_PATIENT") {
              document.getElementById("txtpatient_id").value =
                updatedValue.patient_id;

              msg_display =
                "Data Saved Successfully, Patient Id: " +
                updatedValue.patient_id;
              setTimeout(function () {
                alert(msg_display);
              }, 100);
            } else {
              if (upload_preview_flag === "Y") {
                document.getElementById("txtpatient_id").value = "";
                msg_display =
                  "Patient details populated, click SAVE button for further process.";
              } else {
                document.getElementById("txtpatient_id").value =
                  updatedValue.patient_id;
                msg_display =
                  "Data Uploaded Successfully, Patient Id: " +
                  updatedValue.patient_id;
              }

              if (updatedValue.patient_sex !== "") {
                var objddlpatient_sex1 =
                  document.getElementById("ddlpatient_sex");
                  objddlpatient_sex1.value = updatedValue.patient_sex;
              }

              if (updatedValue.patient_age_unit !== "") {
                var objddlpatient_age_unit1 =
                  document.getElementById("ddlpatient_age_unit");
                  objddlpatient_age_unit1.value = updatedValue.patient_age_unit;
              }


              setTimeout(function () {
                alert(msg_display);
              }, 10);
            }
          }
        })
        .catch((error) => {
          if (error.response.data.message === "Unauthorized") {
            alert("Unauthorized session, try to relogin.");
            logout();
          } else {
            ProcessStatus(false);
            alert(error.response.data.message);
          }
        });
    } catch (err) {
        ProcessStatus(false);
      alert(err.code);
      if (err.code === "404") {
        //setError('Object does not exist.')
        return;
      }
    }
  }
  function ProcessStatus(buttonDisabled) {
    if (buttonDisabled) {
        document.getElementById("btnSave").disabled = true;
        document.getElementById("btnUploadAttachedFiles").disabled = true;
        document.getElementById("btnUploadAttachedFiles").innerText =
          "Please wait..";
    } else {
      document.getElementById("btnSave").disabled = false;
      document.getElementById("btnUploadAttachedFiles").disabled = false;
      
      if (authUser?.upload_preview_flag === "Y") {
        document.getElementById("btnUploadAttachedFiles").innerText =
          " View Selected Files ";
      } else {
        document.getElementById("btnUploadAttachedFiles").innerText =
          " Upload Selected Files ";
      }
    }
  }
  const addClick = (event) => {
    //dispatch(patientActions.getPatient());
    var obj = document.getElementById("txtpatient_id"); obj.value='';
    obj = document.getElementById("txtpatient_name"); obj.value='';
    obj = document.getElementById("txtpatient_age"); obj.value='0';
    obj = document.getElementById("chkurgent"); obj.checked=false;
    obj = document.getElementById("txtremarks"); obj.value='';
    obj = document.getElementById("txtrefer_physician"); obj.value=''; 
    //obj = document.getElementById("txtmodality"); obj.value=''; 
    obj = document.getElementById("txtstudy_description"); obj.value=''; 
    obj = document.getElementById("txtbody_part"); obj.value=''; 
    obj = document.getElementById("txtaddress"); obj.value=''; 

    var objddlpatient_sex = document.getElementById("ddlpatient_sex");
    objddlpatient_sex.value = '';

    var objddlpatient_age_unit = document.getElementById("ddlpatient_age_unit");
    objddlpatient_age_unit.value = 'Y';

    const output = document.querySelector("#result");
    output.innerHTML = "";
    document.getElementById('mainImage').src = '#';
    
    patientData.patient_key= 0;
    patientData.ref_patient_key= 0;
    patientData.patient_id= "";
    patientData.patient_name= "";
    patientData.patient_sex= "M";
    patientData.patient_age= 0;
    patientData.patient_age_unit= "Y";
    patientData.address= "";
    patientData.state= "";
    patientData.zipcode= "";
    patientData.country= "";
    patientData.phone_no= "";
    patientData.email= "";
    patientData.regn_on= "";
    patientData.refer_physician= "";
    patientData.modality= "";
    patientData.body_part= "";
    patientData.study_description= "";
    patientData.study_file= "";
    patientData.remarks= "";
    patientData.internal_comments= "";
    patientData.created_by= "";
    patientData.created_on= "";
    patientData.updated_by= "";
    patientData.updated_on= "";
    patientData.priority= "N";
    patientData.file_upload_key= 0;
    patientData.error_code= "";
    patientData.error_msg= "";

    setFileCount(0);
    
  };
  const resetClick = (event) => {
    alert("Reset");
  };
  function ValidateInput(LbAddMode)
  {
    if(!LbAddMode && uploadImageType === 'JPG'){
      if (!patientData.patient_id) {
        alert("Patient Id cannot be blank");
        return false;
      }
    }

    if(uploadImageType === 'DICOM' && LbAddMode){
      return true;
    }

    if (!patientData.patient_name) {
      alert("Patient name cannot be blank");
      return false;
    } else if (!patientData.patient_age) {
      alert("Patient age cannot be blank");
      return false;
    }
    else if (!patientData.remarks) {
      alert("History cannot be blank");
      return false;
    }
    else if (!patientData.modality) {
      alert("Modality cannot be blank");
      return false;
    } else if (!patientData.study_description) {
      alert("Study name cannot be blank");
      return false;
    }
    return true;
  }
  const saveClick = async (event) => {
    event.preventDefault();
    
    let patient_key = 0;
    patient_key = patientData.patient_key;
    
    var LbAddMode =  false;
    if(patient_key===0)
    {
      if (fileCount === 0) {
        alert("Select files before upload.");
        return false;
      }

      LbAddMode = true;
    }

    if(ValidateInput(LbAddMode)){
      SaveData("SAVE_PATIENT");
    }
  };

  const closeClick = (event) => {
    history.navigate("/home");
  };

  let divWidthStyle = {
    with: "50%",
  };

  let divStyle1 = {
    float: "left",
    marginTop: "4rem",
    marginLeft: "30px",
  };
  let divStyle2 = {
    border: "#dadada 1px solid",
    height: "135px",
  };
  let divStyle3 = {
    float: "right",
    marginTop: "4rem",
    marginRight: "30px",
  };

  return (
    <>
      <main>
        <div className="container-fluid px-4">
          <div className="row">
            <div className="cpl-12 col-lg-4 col-md-4 col-sm-4 mt-2">
              <h5 className="text-blue font-roboto font-weight700">
                <small>Upload Patient Reports </small>
              </h5>
            </div>

            <div className="col-6 col-lg-2 col-md-2 col-sm-2 mt-2 mb-1">
              {/*<button type="reset" className="btn btn-dark btn-sm btn-block text-light bi bi-arrow-clockwise" name="Reset" onClick={resetClick} > RESET</button>*/}
            </div>
            <div className="col-6 col-lg-2 col-md-2 col-sm-2 mt-2 mb-1">
            <button type="button" className="btn btn-secondary btn-sm btn-block text-light bi bi-plus-square" name="Add" onClick={addClick}> ADD</button>
            </div>
            <div className="col-6 col-lg-2 col-md-2 col-sm-2 mt-2 mb-1">
              <button
                type="button"
                className="btn btn-secondary btn-sm btn-block text-light bi bi-save2"
                name="Save"
                id="btnSave"
                onClick={saveClick}
              >
                {" "}
                SAVE
              </button>
            </div>
            <div className="col-6 col-lg-2 col-md-2 col-sm-2 mt-2 mb-1">
              <button
                type="button"
                className="btn btn-dark btn-sm btn-block text-light bi bi-x-circle"
                name="Close"
                onClick={closeClick}
              >
                {" "}
                CLOSE
              </button>
            </div>
          </div>
          <hr className="mt-0" />
          <div className="row">
            <div className="col-lg-4 col-md-12 mb-2">
              <div className="card">
                <div className="card-header bg-gradient-1 text-light">
                  <i className="bi bi-file-earmark-text-fill"></i> Patient
                  Details{" "}
                </div>
                <div className="card-body">
                  <div className="row pe-2 ps-2">
                    <div className="col-4">
                      <div className="mb-2">
                        <label htmlFor="">
                          <small>
                            <i
                              className="fa fa-id-badge"
                              aria-hidden="true"
                            ></i>{" "}
                            ID:
                          </small>
                        </label>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="mb-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Auto Generate ID"
                          aria-describedby=""
                          name="patient_id"
                          id="txtpatient_id"
                          onChange={handleChange}
                          readOnly
                        />{" "}
                        {/* value={patientData.patient_id}  */}
                      </div>
                    </div>
                  </div>
                  <div className="row pe-2 ps-2">
                    <div className="col-4">
                      <div className="mb-2">
                        <label htmlFor="">
                          <small> Name:</small>
                        </label>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="mb-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Patient Name"
                          name="patient_name"
                          id="txtpatient_name"
                          aria-describedby=""
                          value={patientData.patient_name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row pe-2 ps-2">
                    <div className="col-4">
                      <div className="mb-2">
                        <label htmlFor="">
                          <small>
                            <i
                              className="fa fa-venus-mars"
                              aria-hidden="true"
                            ></i>
                            Sex:
                          </small>
                        </label>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="mb-2">
                        <select
                          className="form-select form-select-sm"
                          aria-label=".form-select-sm example"
                          name="patient_sex"
                          id="ddlpatient_sex"
                          onChange={handleChange}
                        >
                          <option value="">Select</option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                          <option value="O">Others</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row pe-2 ps-2">
                    <div className="col-4">
                      <div className="mb-2">
                        <label htmlFor="">
                          <small>
                            <i
                              className="fa fa-calendar"
                              aria-hidden="true"
                            ></i>{" "}
                            Age:
                          </small>
                        </label>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="mb-2">
                        <div>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Age"
                          aria-describedby=""
                          name="patient_age"
                          id="txtpatient_age"
                          value={patientData.patient_age}
                          onChange={handleChange}
                        ></input> 
                        <select
                            className="form-select form-select-sm"
                            aria-label=".form-select-sm example"
                            name="patient_age_unit"
                            id="ddlpatient_age_unit"
                            onChange={handleChange}
                          >
                            <option selected value="Y">Years</option>
                            <option value="M">Months</option>
                            <option value="D">Days</option>
                          </select>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className="row pe-2 ps-2">
                    <div className="col-4">
                      <div className="mb-2">
                        <label htmlFor="">
                          <small>
                            <i
                              className="fa fa-calendar"
                              aria-hidden="true"
                            ></i>{" "}
                            Urgent:
                          </small>
                        </label>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="mb-2">
                        <input
                          type="checkbox"
                          placeholder="Urgent"
                          aria-describedby=""
                          id="chkurgent"
                          value={patientData.priority}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row pe-2 ps-2">
                    <div className="col-4">
                      <div className="mb-2">
                        <label htmlFor="" className="mb-1">
                          <small>
                            <i
                              className="fa fa-plus-square"
                              aria-hidden="true"
                            ></i>{" "}
                            History:
                          </small>
                        </label>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="mb-2">
                        <textarea
                          className="form-control"
                          placeholder="Description"
                          rows="3"
                          name="remarks"
                          id="txtremarks"
                          value={patientData.remarks}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{display:"none"}} className="col-lg-4 col-md-12 mb-2">
              <div className="card">
                <div className="card-header bg-gradient-1 text-light">
                  <i className="bi bi-file-earmark-text-fill"></i> Patient Contact Details{" "}
                </div>
                <div className="card-body">
                  <div className="row pe-2 ps-2">
                    <div className="col-5">
                      <div className="mb-2">
                        <label htmlFor="">
                          <small>
                            <i
                              className="fa fa-envelope-o"
                              aria-hidden="true"
                            ></i>{" "}
                            Email:
                          </small>
                        </label>
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="mb-2">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          aria-describedby=""
                          name="email"
                          value={patientData.email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row pe-2 ps-2">
                    <div className="col-5">
                      <div className="mb-2">
                        <label htmlFor="">
                          <small>
                            <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                            Phone No.:
                          </small>
                        </label>
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="mb-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Phone No."
                          aria-describedby=""
                          name="phone_no"
                          value={patientData.phone_no}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  
                  <div className="row pe-2 ps-2">
                    <div className="col-5">
                      <div className="mb-2">
                        <label htmlFor="">
                          <small>
                            <i className="fa fa-globe" aria-hidden="true"></i>{" "}
                            Country:
                          </small>
                        </label>
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="mb-2">
                        <select
                          className="form-select form-select-sm"
                          aria-label=".form-select-sm example"
                          name="country"
                          value={patientData.country}
                          onChange={handleChange}
                        >
                          <option>Select</option>
                          <option value="India">India</option>
                          <option value="Bangladesh">Bangladesh</option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row pe-2 ps-2">
                    <div className="col-5">
                      <div className="mb-2">
                        <label htmlFor="" className="mb-1">
                          <small>
                            <i
                              className="fa fa-plus-square"
                              aria-hidden="true"
                            ></i>{" "}
                            Internal Comments:
                          </small>
                        </label>
                      </div>
                    </div>
                    <div className="col-7">
                      <div className="mb-2">
                        <textarea
                          className="form-control"
                          placeholder="Internal Comments"
                          rows="3"
                          name="internal_comments"
                          value={patientData.internal_comments}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 mb-2">
              <div className="card">
                <div className="card-header bg-gradient-1 text-light">
                  <i className="bi bi-file-earmark-text-fill"></i> Patient Case
                  Details{" "}
                </div>
                <div className="card-body">
                  <div className="row pe-2 ps-2">
                    <div className="col-4">
                      <div className="mb-2">
                        <label htmlFor="">
                          <small>
                            <i className="fa fa-user-md" aria-hidden="true"></i>{" "}
                            Refer Physician:
                          </small>
                        </label>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="mb-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Refer Physician"
                          name="refer_physician"
                          id="txtrefer_physician"
                          value={patientData.refer_physician}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row pe-2 ps-2">
                    <div className="col-4">
                      <div className="mb-2">
                        <label htmlFor="">
                          <small>
                            <i className="fa fa-medkit" aria-hidden="true"></i>{" "}
                            Modality:
                          </small>
                        </label>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="mb-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Modality"
                          name="modality"
                          txt="txtmodality"
                          value={patientData.modality}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row pe-2 ps-2">
                    <div className="col-4">
                      <div className="mb-2">
                        <label htmlFor="">
                          <small>
                            <i
                              className="fa fa-stethoscope"
                              aria-hidden="true"
                            ></i>{" "}
                            Study Description:
                          </small>
                        </label>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="mb-2">
                        <textarea
                          className="form-control"
                          placeholder="Description"
                          rows="2"
                          name="study_description"
                          id="txtstudy_description"
                          value={patientData.study_description}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="row pe-2 ps-2">
                    <div className="col-4">
                      <div className="mb-2">
                        <label htmlFor="" className="mb-1">
                          <small>
                            <i
                              className="fa fa-heartbeat"
                              aria-hidden="true"
                            ></i>{" "}
                            Body Part:
                          </small>
                        </label>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="mb-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Body Part"
                          name="body_part"
                          id="txtbody_part"
                          value={patientData.body_part}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row pe-2 ps-2">
                    <div className="col-12">
                      <div className="mb-2">
                        <label htmlFor="" className="mb-1">
                          <small>
                            <i
                              className="fa fa-paperclip"
                              aria-hidden="true"
                            ></i>{" "}
                            Attach History File:{" "}
                          </small>
                        </label>
                        <input
                          type="file"
                          className="form-control form-control-sm"
                          placeholder="Uplaod History"
                          aria-describedby=""
                          name="study_file"
                          onChange={handleChangeAttach}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row pe-2 ps-2">
                    <div className="col-4">
                      <div className="mb-2">
                        <label htmlFor="">
                          <small>
                            <i
                              className="fa fa-address-book-o"
                              aria-hidden="true"
                            ></i>{" "}
                            Address:
                          </small>
                        </label>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="mb-2">
                        <textarea
                          className="form-control"
                          placeholder="Address"
                          rows="1"
                          name="address"
                          id="txtaddress"
                          value={patientData.address}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-12 mb-2">
              <div className="card">
                <div className="card-body">
                  <div className="row pe-2 ps-2">
                    <div className="col-12">
                      <div className="mb-2">
                        <div style={{height: "43vh"}} className="p-1 border-rounded-crimson image-container mt-1">
                          <img style={{width:"100%"}} id="mainImage" src="#" alt="ReportImage"></img>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 mb-2">
              <div className="card">
                <div className="card-header bg-gradient-1 text-light">
                  <i className="bi bi-clipboard2-pulse-fill"></i> Upload Report Images{" "}
                </div>
                <div className="card-body">
                  <div className="row ps-2 pe-2">
                    <div className="col-12 col-md-12 text-center">
                      <div className="row">
                        <div className="col-3 text-left pl-2">
                          <label htmlFor="files">
                            <small>Select files </small>
                          </label>
                          <input
                            id="files"
                            className="form-control-sm"
                            type="file"
                            multiple="multiple"
                            onChange={handleChangeUploadFile}
                          />
                          <br />
                          <br />
                          <button
                            type="button"
                            id="btnUploadAttachedFiles"
                            onClick={pullPouplate}
                          >
                            {" "}
                            &nbsp; View Selected Files &nbsp;{" "}
                          </button>
                        </div>
                        <div className="col-9 report-bg">
                          <div className="row mt-3">
                            <div className="col-1">
                              <a href=" #" title="prev">
                                <div style={divStyle1}>
                                  <i className="bi bi-caret-left-fill"></i>
                                </div>
                              </a>
                            </div>
                            <div className="col-10" style={divStyle2}>
                              <output id="result"></output>
                            </div>
                            <div className="col-1">
                              <a href=" #" title="next">
                                <div style={divStyle3}>
                                  <i className="bi bi-caret-right-fill"></i>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {users.loading && (
        <div className="spinner-border spinner-border-sm"></div>
      )}
      {users.error && (
        <div className="text-danger">
          Error loading users: {users.error.message}
        </div>
      )}
    </>
  );
}
